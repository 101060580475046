<template>
  <v-navigation-drawer
  
    v-model="Sidebar_drawer"
    :dark="SidebarColor !== 'white'"
    :color="SidebarColor"
    mobile-break-point="960"
    clipped
    :right="$vuetify.rtl"
    mini-variant-width="70"
    :expand-on-hover="expandOnHover"
    app
    id="main-sidebar"
    v-bar
  >
    <!---USer Area -->
    <v-list-item two-line class="profile-bg text--center">
      <!-- <v-list-item-avatar>
        <img src="https://randomuser.me/api/portraits/men/81.jpg" />
      </v-list-item-avatar> -->

      <v-list-item-content class="white--text ">
        <v-list-item-title>{{ fullName }}</v-list-item-title>
        <v-list-item-subtitle class="caption white--text ">
          {{ title }}</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>
    <!---USer Area -->
    <v-list expand nav class="mt-1" v-if="menu && menu.length > 0">
      <template v-for="(item, i) in menu">
        <!---If Sidebar Caption -->
        <v-row v-if="item.header" :key="item.header" align="center">
          <v-col cols="12">
            <v-subheader v-if="item.header" class="d-block text-truncate">{{
              item.header
            }}</v-subheader>
          </v-col>
        </v-row>
        <!---If Sidebar Caption -->
        <BaseItemGroup
          v-else-if="item.children"
          :key="`group-${i}`"
          :item="item"
        ></BaseItemGroup>

        <BaseItem v-else :key="`item-${i}`" :item="item" />
      </template>
      <!---Sidebar Items -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
//import { mapState } from "vuex";
import SidebarItems from "./SidebarItems";
import { mapGetters } from 'vuex';
export default {
  name: "Sidebar",
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    items: SidebarItems,
  }),
  computed: {
    ...mapGetters("theme", ({
        SidebarColor: 'SidebarColor'
    })),
    Sidebar_drawer: {
        get() {
            console.log("sidebar get()");
            console.log(this.$store.state.Sidebar_drawer);
        return this.$store.state.Sidebar_drawer;
      },
        set(val) {
            console.log("sidebar set(val");
            console.log(val);
        this.$store.commit("SET_SIDEBAR_DRAWER", val);
      },
    },
    ...mapGetters("user", ({ 
        emailAddress: 'emailAddress',
        title: 'title',
        fullName: 'fullName'
    })),
    getUserPic() {
      return this.$store.state.user.userPic;
    },
               isTech() {
      return this.$store.state.user.isTech;
      },
      isTechManager() {
          return this.$store.state.user.isTechManager;
      },
      menu() {
          if (this.isTech) {
              for (var i = this.items.length - 1; i >= 0; --i) {
                  if (i != 0) {
                      if (this.items[i].title == "Schedule") {
                          this.items.splice(i, 1);
                      }
                      else if (this.items[i].title == "Administration") {
                          this.items.splice(i, 1);
                      }
                      else if (this.items[i].title == "Patients") {
                          this.items.splice(i, 1);
                      }
                  }
              }

              return this.items;
          }
          else if (this.isTechManager) {
              for (var i = this.items.length - 1; i >= 0; --i) {
                  if (i != 0) {
                      if (this.items[i].title == "Administration") {
                          this.items.splice(i, 1);
                      }
                  }
              }

              return this.items;
          }            
            else {
            return SidebarItems;
          }
        }
  },
  watch: {
    "$vuetify.breakpoint.smAndDown"(val) {
      this.$emit("update:expandOnHover", !val);
    },
  },
  methods: {},
  async mounted() {
    this.$store.dispatch("user/getProfile");
    //this.$store.dispatch("user/getUserPic");
    this.$store.dispatch("user/getUserRoles")
  },
};
</script>
<style lang="scss">
#main-sidebar {
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  .v-navigation-drawer__border {
    display: none;
  }
  .v-list {
    padding: 8px 15px;
  }
  .v-list-item {
    &__icon--text,
    &__icon:first-child {
      justify-content: center;
      text-align: center;
      width: 20px;
    }
  }
  .profile-bg {
    background: url("../../../assets/images/user-info.jpg") no-repeat;
    .v-avatar {
      padding: 45px 0;
    }
  }
}
</style>
