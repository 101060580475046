<template>
    <v-app id="inspire">
        <Header v-model="expandOnHover"
                :isLightMode="isLightMode"
                :isDarkMode="isDarkMode"></Header>

        <v-content>
            <v-container fluid class="page-wrapper">
                <router-view />
                <v-btn bottom
                       color="primary"
                       dark
                       fab
                       small
                       fixed
                       right
                       @click.stop="setCustomizerDrawer(!Customizer_drawer)">
                    <v-icon>mdi-cog</v-icon>
                </v-btn>
                <v-snackbar v-model="snackbar" :multi-line="true">
                    {{ message }}
                    <template v-slot:action="{ attrs }">
                        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
                            Close
                        </v-btn>
                    </template>
                </v-snackbar>
            </v-container>
        </v-content>
        
        <Sidebar :expand-on-hover.sync="expandOnHover"></Sidebar>

        <Customizer v-model="expandOnHover"></Customizer>

        <!-- <Footer></Footer> -->
    </v-app>
</template>

<script>
import Header from "./header/Header";
import Sidebar from "./sidebar/Sidebar";
import Footer from "./footer/Footer";
import Customizer from "./customizer/Customizer";
import Proxyable from "vuetify/lib/mixins/proxyable";

import { mapState, mapMutations } from "vuex";
export default {
  name: "Layout",
  mixins: [Proxyable],
  components: {
    Header,
    Sidebar,
    // eslint-disable-next-line vue/no-unused-components
    Footer,
    Customizer,
  },

  props: {
    source: String,
  },
  data: () => ({
    expandOnHover: false,
    message: "",
    snackbar: false,
  }),
  watch: {
    snackAttack: function(newValue) {
      if (newValue) {
        this.message = newValue;
        this.snackbar = true;
        this.$store.commit("snack/set_snack", "");
      }
    },
  },
  computed: {
    ...mapState(["Customizer_drawer"]),
    isDarkMode() {
      return this.isDark;
    },
    isLightMode() {
      return this.isLight;
    },
    snackAttack() {
      return this.$store.state.snack.attack;
    },
  },
  methods: {
    ...mapMutations({
      setCustomizerDrawer: "SET_CUSTOMIZER_DRAWER",
    }),
  },
  created: function() {
    const msg = this.$store.state.snack.attack;
    if (msg !== "") {
      this.snackbar = true;
      this.message = this.$store.state.snack.attack;
      this.$store.commit("snack/set_snack", "");
    }
  },
};
</script>

<style></style>
