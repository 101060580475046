export default [
    {
        header: "Admin",
        title: 'Dashboard',},
    {
        icon: 'mdi-file',
        title: 'Dashboard',
        to: '/dashboard',
    },
    {
        group: '/administration',
        model: false,
        icon: 'mdi-lock',
        title: 'Administration',
        children: [
            {
                title: 'Users',
                icon: 'mdi-adjust',
                to: 'users',
            },
        ]
    },
    {
        icon: 'mdi-account-plus',
        title: 'Patients',
        to: '/patients',
    },
    {
        icon: 'mdi-calendar',
        title: 'Schedule',
        to: '/schedule',
    }, 
]