<template>
  <v-navigation-drawer
    v-model="Customizer_drawer"
    app
    temporary
    :right="true"
  >
    <!---Theme Color -->
    <div class="px-4 py-4">
      <span>Theme</span>
      <v-switch
        v-model="isDarkMode"
        class="mt-2"
        hide-details
        primary
        label="Dark"
      />
    </div>
    <v-divider></v-divider>
    <!---Sidebar Type -->
    <div class="px-4 py-4">
      <span>Mini Sidebar</span>
      <v-switch
        v-model="internalValue"
        hide-details
        class="mt-2"
        label="Mini variant"
      ></v-switch>
    </div>
    <v-divider></v-divider>
    <div class="px-4 py-4">
      <span>Primary Color</span>
      <v-color-picker v-model="setPrimaryColor"/>
    </div>

    <!---Theme color -->
    <div class="px-4 py-4">
      <span>Navbar Color</span>
       <v-color-picker v-model="setTopbarColor"/>
    </div>
    <v-divider></v-divider>
    <!---Sidebar Color -->
    <div class="px-4 py-4">
      <span>Sidebar Color</span>
      <v-color-picker v-model="setSidebarColor"
      />
    </div>
    <!---End Sidebar Color -->
  </v-navigation-drawer>
</template>

<script>
import Proxyable from "vuetify/lib/mixins/proxyable";
export default {
  name: "Customizer",
  mixins: [Proxyable],
  data: () => ({
    right: false,
  }),
  computed: {
    Customizer_drawer: {
      get() {
        return this.$store.state.Customizer_drawer;
      },
      set(val) {
        if (val) {
        this.$store.commit("SET_CUSTOMIZER_DRAWER", val);
        }
      },
    },
    setSidebarColor: {
      get() {
        return this.$store.state.theme.SidebarColor;
      },
      set(val) {
        this.$store.commit("theme/SET_SIDEBAR_COLOR", val);
      },
    },
    setTopbarColor: {
      get() {        
        return this.$store.state.theme.TopbarColor;
      },
      set(val) {
        if (val) {
        this.$store.commit("theme/SET_TOPBAR_COLOR", val);
        }
      },
    },
     setPrimaryColor: {
      get() {
        return this.$store.state.theme.PrimaryColor;
      },
      set(val) {
        console.log('val',val)
        this.$store.commit("theme/SET_PRIMARY_COLOR", val); 
        }
      
     },  
    isDarkMode: {
      get() {
        return this.$store.state.theme.isDarkMode;
      },
      set(val) {
        this.$store.commit("theme/SET_DARK_MODE", val);
      },
    },
  },
  methods: {},
};
</script>

<style lang="scss">
.v-application .v-item-group .v-sidebar_active {
  border: 1px solid black !important;
}
</style>

